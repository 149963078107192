<template>
    <div><span class="registry_fields_label"></span>
        <label>
            <input
                    type="checkbox"
                    v-model="local.checked"
            >Копировать из сценария
        </label>
        <div v-if="local.checked">
            <el-select
                    v-model="local.selectedItem"
                    @change="$emit('input', local.selectedItem)"
            >
                <el-option
                        v-for="(item) in local.fields"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div v-if="!local.checked">
            <label>Данные будут скопированы из заявки</label>
        </div>
        <div v-if="local.checked">
            <label>Данные будут скопированы из выбранного сценария</label>
        </div>
    </div>
</template>
<script>
export default {
  props: ['model'],
  name: 'FillScenarioFromSourceCmp',
  data () {
    return {
      local: this.model
    }
  },
  watch: {
    local () {
      this.model = this.local
    },
    model () {
      this.local = this.model
    }
  }
}
</script>

<style scoped>

</style>
